<template>
  <div class="main">

    <div class="top">
      <div class="nav blue-bcg zet">
        <div class="content flex-top">
          <a href="#heading" class="href"><img src="../assets/logo-BS-white.svg" alt="buildsys logo" class="logo"></a> <a href="http://www.buildsys.cz" target="_blank" class="href">www.buildsys.cz</a> 
        </div>
      </div>

      <div class="heading full-bcg-home full-height blue-bcg" id="heading">
        <div class="content full-height hello-content">
          <img src="../assets/titulka-texty.svg" alt="Automatizace budov" class="main-title display-center">
          <h4 class="text-center italic tenh3">Velké úspory ukryté v malých detailech</h4>
          <div class="flex-1">
            <ul class="list-o">
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#automatizace" class="href links">Úvod</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#mytus-1" class="href links">Mýtus<img src="../assets/hashtag-white.svg" alt="hashtag">1</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#mytus-2" class="href links">Mýtus<img src="../assets/hashtag-white.svg" alt="hashtag"> 2</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#mytus-3" class="href links">Mýtus<img src="../assets/hashtag-white.svg" alt="hashtag"> 3</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#mytus-4" class="href links">Mýtus<img src="../assets/hashtag-white.svg" alt="hashtag"> 4</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#smysl" class="href links">Proč s námi?</a></li>
              <li class="percent-flex"><div class="li-o yellow-text">o</div><a href="#kontakt" class="href links">Kontakt</a></li>
            </ul>
            <img src="../assets/sipka-2.svg" alt="yellow arrow" class="arrow"> 
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="img-sekce">
      <img src="../assets/foto/CAM01_final.jpg" alt="budova" class="budova media-none">
      <img src="../assets/foto/IMG_6551.jpg" alt="budova" class="budova media-none-2">
      <img src="../assets/foto/2141012_08_LQ.jpg" alt="budova" class="budova media-none-3">
      <img src="../assets/foto/dorn4.jpg" alt="budova" class="budova media-none-4">
    </div> -->

    <div class="pages">
      <div class="page2 blue-bcg margin0" id="automatizace">
        <div class="content">
          <div class="page-padding">
            <h2 class="h2">Co je vlastně automatizace budov?</h2>
            <div class="paragraph">
              <p>Nudnou teorii přeskočíme ☺</p>
              <p>V praxi to znamená, že jednotlivé technologie svým promyšleným nastavením a vzájemnou spoluprací zajišťují to, <strong>aby se uživatelé cítili komfortně a bezpečně</strong>, a zároveň aby <strong>provoz budovy byl co nejekonomičtější</strong>.</p>
            </div>
          </div>
          
                   
        </div>
        <div class="skica">
            <img src="../assets/budovy.svg" alt="budovy" class="budovy">
            <img src="../assets/silocary-2.svg" alt="silocary" class="silocary">
          </div> 
      </div>

      <div class="page3 padding20 content">
        <h3 class="blue-text h3">Jak by to mělo vypadat</h3>
        <h4 class="blue-text h4 b40">(a bohužel často nevypadá)</h4>
        <ul class="list">
          <li class="flex-list">
            <img src="../assets/ikona-klima.svg" alt="ikona-klimatizace">
            <p>Interiér si udržuje optimální teplotu (v každé místnosti může být jiná). Dokáže reagovat i na to, že za okny zrovna svítí slunce nebo naopak dorazila studená fronta.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-vetrak.svg" alt="ikona větrák">
            <p>Všude je čerstvý vzduch. Nevydýchá se ani v případě, že se celá místnost zaplní lidmi.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-roznuto-zhasnuto.svg" alt="ikona rožnuto zhasnuto">
            <p>Světla se automaticky zapínají a vypínají podle toho, zda je někdo v místnosti. Systém osvětlení zároveň spolupracuje s žaluziemi / venkovními roletami a přizpůsobuje intenzitu svícení aktuálním podmínkám.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-kormidlo.svg" alt="ikona kormidlo">
            <p>V budově fungují nejrůznější bezpečnostní čidla a systémy, které neustále monitorují celý objekt. Jakmile dojde k nějakému problému, upozorní na něj, případně samy zareagují.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-baterie.svg" alt="ikona baterie">
            <p>Při výpadku energie naskočí záložní zdroje.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-dve-sipky.svg" alt="ikona dve sipky">
            <p>Jednotlivé systémy spolu navzájem komunikují, případně pracují s externími daty získanými pomocí internetu.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-sipky-do-stredu.svg" alt="ikona sipky do stredu">
            <p>Veškeré údaje se ukládají na jednom místě.</p>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-monitoring.svg" alt="ikona monitoring">
            <p>Současně se vše z jediného místa ovládá prostřednictvím přehledné řídicí platformy.</p>
          </li>
        </ul>
      </div>

      <div class="page4 padding20 content">
        <h3 class="blue-text h3">Co byste o automatizaci budov měli vědět</h3>
        <h4 class="blue-text h4 b40">(a dost možná nevíte)</h4>
        <ul class="list">
          <li class="flex-numbers"><div class="li-number blue-text">1.</div><p>Je nedílnou součástí moderních staveb.</p></li>
          <li class="flex-numbers"><div class="li-number blue-text">2.</div><p>I když je automatizace nenápadná, představuje nosný pilíř tzv. chytrých budov a smart cities.</p></li>
          <li class="flex-numbers"><div class="li-number blue-text">3.</div><p>Investičně takřka není rozdíl, jestli se udělá nadprůměrně, nebo podprůměrně.</p></li>
          <li class="flex-numbers"><div class="li-number blue-text">4.</div><p>Kvalita řešení ovšem určuje náklady na provoz budovy. Ty se mohou lišit o stovky tisíc i miliony korun ročně.</p></li>
          <li class="flex-numbers"><div class="li-number blue-text">5.</div><p>Dobře nastavená automatizace znamená nejen úspory, ale i vysoký komfort uživatelů a šetrný přístup k životnímu prostředí.</p></li>
          <li class="flex-numbers"><div class="li-number yellow-text">!</div><p>Investoři tento obor přesto (zatím) podceňují, často mají zkreslené představy.</p></li>
        </ul>
      </div>

      <div class="page5 blue-bcg padding-blue-page blue-my">
        <img src="../assets/kresba-strana-5.svg" alt="four dots" class="display-center">
        <div class="margin-top-h2">
          <h2 class="blue-h2 margin-bottom-h2">4 nejčastější mýty</h2>
          <h2 class="blue-h2">o automatizaci budov</h2>
        </div>
      </div>

      <div class="page6 grey-bcg page-padding" id="mytus-1">
        <div class="content">
          <div class="mytus-flex">
            <h2 class="mytus mytus-grey">
              Mýtus
              <img src="../assets/hashtag.svg" alt="hashtag">
              1
            </h2>
            <h2 class="mytus">Máme automatizované technologie = máme chytrou budovu.</h2>            
          </div>
          <p><strong>Velký omyl.</strong></p>
          <p>Řada investorů věří, že pokud zautomatizují jednotlivé technologie, budou mít chytrou budovu. Výsledkem může být pravý opak.</p>
          <p><strong>Pokud se technologie řeší odděleně</strong>, nemusí být vzájemně kompatibilní. V lepším případě jen <strong>nevyužijí svůj potenciál</strong>. V horším <strong>mohou jít přímo proti sobě.</strong></p>
          <div class="mytus1">
            <img src="../assets/obr-strana-6.svg" alt="klima vs heater" class="display-center">
            <p>V přechodových obdobích může ve špatně automatizovaných budovách docházet k bizarní situaci, kdy se interiér současně ochlazuje i vytápí. Další paradox: Čím víc se topí, tím víc se chladí. Čidla topení totiž reagují na chlad, čidla klimatizace na rostoucí teplotu.</p>
          </div>
          <p><strong>Výsledek?</strong></p>
          <ul class="list-checked">
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Zbytečně vysoké náklady na energie</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Nekomfortní prostředí</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Komplikované řízení (technologie nespolupracují, každá má vlastní řídicí systém)</p>
            </li>
          </ul>          
        </div>
      </div>

      <div class="page7 page-padding content">
        <h3 class="blue-text mytus b40 h3-hashtag">
          <img src="../assets/hashtag-blue.svg" alt="hashtag">
          1 Jak to udělat správně?
        </h3>
        <div class="list">
          <p><strong>Klíčem je maximální integrace.</strong></p>
          <div class="inline-text">
            <span class="blue-text bold space">Jednotlivé technologie</span>
            <span>je třeba sestavit tak, aby byly vzájemně <strong>kompatibilní.</strong></span>
          </div>
          <div class="media-flex">
            <div class="blue-table">
              <ul class="list-checked">
                <li>Zabezpečovací, přístupové a kamerové systémy</li>
                <li>Elektrické rozvody</li>
                <li>Klimatizační a rekuperační jednotky, vytápění</li>
                <li>Osvětlení, ovládání rolet a žaluzií</li>
                <li>Obnovitelné zdroje energie, systémy záložního napájení</li>
                <li>Řízení výtahů atd.</li>
              </ul>
            </div>
            <div class="flex-column">
              <ul class="list-checked right-list blue-text">
                <li>Díky internetu mohou čerpat <strong>informace od sebe navzájem</strong> i z dalších zdrojů.</li>
                <li>Na změny reagují autonomně a s předstihem.</li>
                <li><strong>Vše ovládá JEDNOTNÝ ŘÍDICÍ SYSTÉM.</strong></li>
                <li><strong>Řídicí platforma zároveň ukládá veškeré důležité informace.</strong></li>
                <li>
                  <ul class="minilist">
                    <li>Integrovaný systém šetří čas při kontrole.</li>
                    <li>Včas předchází problematickým stavům.</li>
                    <li>Zpracovává manažerské přehledy.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex-end">
            <img src="../assets/ikona-terc.svg" alt="ikona terč" class="terc">
          </div> 
        </div>
      </div>

      <div class="page8 grey-bcg page-padding" id="mytus-2">
        <div class="content">
          <div class="mytus-flex">
            <h2 class="mytus mytus-grey">
              Mýtus
              <img src="../assets/hashtag.svg" alt="hashtag">
              2
            </h2>
            <h2 class="mytus">Projektant tomu rozumí.</h2>            
          </div>

          <p><strong>Kéž by to tak bylo.</strong></p>
          <p>Technologie používané v budovách se však vyvíjejí takovým tempem, že s ním velká část projektantů nezvládá držet krok. Mladých odborníků je navíc extrémní nedostatek a starší generace se odmítá učit nové věci.</p>
          <p>Často tak vznikají <strong>myšlenkově zastaralé projekty</strong>, postavené na více než 10 let starých technologiích.</p>
          <div class="mytus1">
            <img src="../assets/obr-strana-8.svg" alt="klima vs heater" class="display-center">
            <p>Ano, je to stejné jako ohromovat dnes někoho tlačítkovou Nokií.</p>
          </div>
          <div class="flex-desktop">
            <p><strong>Výsledek?</strong></p>
            <img src="../assets/arrow_right.svg" alt="arrow right">
            <p><strong>Z nekvalitního projektu nemůže vzniknout kvalitní budova.</strong></p>
          </div>
        </div>
      </div>

      <div class="page9 page-padding content">
        <h3 class="blue-text mytus b40 h3-hashtag">
          <img src="../assets/hashtag-blue.svg" alt="hashtag">
          2 Jak to udělat správně?
        </h3>
        <div class="list">
          <p><strong>Kvalitní projekt je u nové stavby alfou omegou.</strong></p>
          <p class="blue-text"><strong>Nepodceňte to.</strong></p>
          <div>
            <div class="blue-table blue-table-flex">
              <ul class="list-checked">
                <li><strong>Automatizaci řešte se skutečnými profesionály.</strong> Zjistěte si dopředu reference.</li>
                <li>Důvěřujte, ale prověřujte. Ptejte se, ptejte se, ptejte se.</li>
                <li>Poptejte <strong>KOMPLEXNÍ INTEGROVANÝ SYSTÉM AUTOMATIZACE BUDOVY</strong> už v zadání.</li>
                <li><strong>Požadujte moderní nadčasové technologie</strong> odpovídající době vzniku projektu.</li>
                <li>Pokud se v projektu objeví nedokonalosti, předělejte ho. Vyplatí se to.</li>
              </ul>
              <div class="terc-flex">
                <img src="../assets/ikona-terc-white.svg" alt="ikona terč" class="terc-white">
              </div>
            </div>
          </div>
          <p class="blueh3 blue-text">Je jednodušší budovu překreslit než přestavět.</p>          
        </div>
      </div>

      <div class="page10 grey-bcg page-padding" id="mytus-3">
        <div class="content">
          <div class="mytus-flex">
          <h2 class="mytus mytus-grey">
              Mýtus
              <img src="../assets/hashtag.svg" alt="hashtag">
              3
            </h2>
          <h2 class="mytus">Na to, aby se to udělalo líp, nejsou peníze.</h2>            
          </div>

          <p><strong>Je to přesně naopak.</strong></p>
          <p>Ve skutečnosti byste si měli říct, že nemáte dost peněz na to, aby se to udělalo hůř. V automatizaci budov totiž platí, že <strong>kvalitní neznamená dražší.</strong></p>
          <ul class="list-checked">
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p><strong>Vypracovat špatný projekt stojí prakticky stejně jako navrhnout nejlepší možné řešení.</strong></p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Alarmující rozdíly se bohužel projeví až po kolaudaci.</p>
            </li>
          </ul>
          <p class="blueh3 tenh3">Špatně navržené řešení prodraží provoz budovy o stovky tisíc až miliony korun ročně!</p>
          <img src="../assets/obr-strana-10.svg" alt="white buldings" class="display-center buildings">          
        </div>
      </div>

      <div class="page11 page-padding content">
        <h3 class="blue-text mytus b40 h3-hashtag">
          <img src="../assets/hashtag-blue.svg" alt="hashtag">
          3 Jak to udělat správně?
        </h3>
        <div class="list">
          <div class="inline-text">
            <span class="blue-text bold space">Zapamatujte si čísla 4 a 80.</span>
            <span>Zvolte si, jestli díky nim chcete každoročně prodělávat, nebo šetřit.</span>
          </div>
          <div class="flex-table">
            <div class="percent-flex">
              <div class="blue-box text-center">4 %</div>
              <div>
                <!-- <p class="bold tenh3">Tolik stojí automatizace budovy. Přesněji 2-4 % z celkového rozpočtu na stavbu.</p>
                <p class="blueh3 blue-text display-velke font-small"><strong>Vždycky.</strong> Bez ohledu na to, jestli se projekt udělá dobře, nebo špatně. <strong>Vy si vyberte možnost „dobře“ a plaťte za kvalitu.</strong> Ušetříte. Hodně.</p> -->
                <div class="margin-bottom-h2">
                 <p class="bold tenh3 margin-bottom-h2">Investice do automatizace budov obvykle představuje 4 % z celkového rozpočtu na stavbu. Bez ohledu na kvalitu.</p> 
                </div>
                <p class="blueh3 blue-text display-velke font-small">(Pod)průměr a nadstandard výjdou investičně prakticky nastejno.</p>
              </div>
            </div>
            <!-- <p class="blueh3 blue-text display-male font-small"><strong>Vždycky.</strong> Bez ohledu na to, jestli se projekt udělá dobře, nebo špatně. <strong>Vy si vyberte možnost „dobře“ a plaťte za kvalitu.</strong> Ušetříte. Hodně.</p> -->
            <p class="blueh3 blue-text display-male font-small">(Pod)průměr a nadstandard výjdou investičně prakticky nastejno.</p>
            <div class="percent-flex">
              <div class="blue-box text-center">80 %</div>
              <div>
                <!-- <p class="bold tenh3">Takový vliv má automatizace na výsledné provozní náklady.</p>
                <p class="blueh3 blue-text display-velke font-small">Jinými slovy <strong>NIC JINÉHO NEPŮSOBÍ NA EKONOMIKU BUDOVY TAK VÝRAZNĚ.</strong> Automatizace zároveň určuje i komfort uživatelů a vztah k okolnímu prostředí. <strong>Takže zpátky k předchozímu bodu: Opravdu se vyplatí 2-4 procentům věnovat pozornost.</strong></p> -->
                <div class="margin-bottom-h2">
                  <p class="bold tenh3">Kvalita provedení má vliv na cca 80 % spotřeby energie v budově. = Výsledné náklady se mohou propastně lišit.</p>
                </div>
                <div class="margin-bottom-h2">
                  <p class="bold tenh3 display-velke"><strong>Nekvalitní realizace se prodraží po kolaudaci.</strong></p>
                </div>
                <div>
                  <p class="blueh3 blue-text display-velke font-small">O to důležitější je včasná volba správného dodavatele.</p>
                </div>
              </div>
            </div>
            <!-- <p class="blueh3 blue-text display-male font-small">Jinými slovy <strong>NIC JINÉHO NEPŮSOBÍ NA EKONOMIKU BUDOVY TAK VÝRAZNĚ.</strong> Automatizace zároveň určuje i komfort uživatelů a vztah k okolnímu prostředí. <strong>Takže zpátky k předchozímu bodu: Opravdu se vyplatí 2-4 procentům věnovat pozornost.</strong></p> -->
            <div class="margin-bottom-h2">
              <p class="bold tenh3 display-male"><strong>Nekvalitní realizace se prodraží po kolaudaci.</strong></p>
            </div>
            <div>
              <p class="blueh3 blue-text display-male font-small">O to důležitější je včasná volba správného dodavatele.</p>
            </div>
            
            
          </div>
          <div class="flex-11">
            <p class="kviz-11 italic"><strong>Kvíz: Která budova má správně vyřešenou automatizaci?</strong></p>
            <div class="flex-end">
              <img src="../assets/ikona-terc.svg" alt="ikona terč">
            </div>
          </div>
          
          <img src="../assets/obr-strana-11-3.svg" alt="two buldings" class="display-center buildings-11">
          <p class="text-11">Vypadají stejně. Náklady na stavbu byly stejné. Roční provoz budovy B vyjde o 780 000 Kč levněji.</p>          
        </div>
      </div>

      <div class="page12 grey-bcg page-padding" id="mytus-4">
        <div class="content">
          <div class="mytus-flex">
            <h2 class="mytus mytus-grey">
              Mýtus
              <img src="../assets/hashtag.svg" alt="hashtag">
              4
            </h2>
            <h2 class="mytus">Případně to vylepšíme. Potom.</h2>            
          </div>
          <p><strong>Klasická mylná představa.</strong></p>
          <p>A typický scénář, který jí předchází:</p>
          <p>Na začátku bývá plán postavit skvělou moderní budovu.<br>Její automatizaci ovšem nikdo neřeší komplexně.<br> Co technologie, to jiný dodavatel a jiné ovládání.</p>
          <p>Po kolaudaci se začnou ukazovat nedokonalosti nesourodých systémů.<br>Provoz budovy je navíc nákladnější, než se předpokládalo.<br>Zjevně je něco špatně.</p>
          <p><strong>Co s automatizací, která nefunguje podle představ?</strong> <br> Zdá se logické pozvat odborníka a chyby napravit. <strong>Tak jednoduché to ale není.</strong></p>
          <ul class="list-checked">
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Něco se vyřešit dá. Je možné například optimalizovat nastavení jednotlivých technologií. Některé se dají lépe zkoordinovat.</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Pokud je ale systém špatně řešený jako celek, dá se napravit jen velmi komplikovaně.</p>
            </li>
          </ul>
          <div class="flex-stopy">
            <img src="../assets/ikona-stopy.svg" alt="stopy">
            <img src="../assets/ikona-stopy.svg" alt="stopy">
            <img src="../assets/ikona-stopy.svg" alt="stopy">
            <img src="../assets/ikona-stopy.svg" alt="stopy">
            <img src="../assets/ikona-stopy.svg" alt="stopy" class="media-700">
            <img src="../assets/ikona-stopy.svg" alt="stopy" class="media-700">
          </div>
          <p class="text-12">Všechny kroky by měly směřovat správným směrem. Vracet se ve vlastních stopách je neekonomické.</p>          
        </div>
      </div>

      <div class="page13 page-padding content">
        <h3 class="blue-text mytus b40 h3-hashtag">
          <img src="../assets/hashtag-blue.svg" alt="hashtag">
          4 Jak to udělat správně?
        </h3>
        <div class="list">
          <div class="inline-text">
            <span class="blue-text bold space">Ideální je problémům předejít.</span>
            <span>Už to tu bylo několikrát:</span>
          </div>
          <div>
            <div class="blue-table blue-table-flex">
              <ul class="list-checked">
                <li>Důsledně se automatizaci věnujte <strong>od samého začátku.</strong></li>
                <li><strong>Vyberte si kvalitní projektanty.</strong></li>
                <li>Zaměřte se na komplexnost řešení. Poptejte <strong>integrovaný systém</strong>, kde jednotlivé technologie budou vzájemně spolupracovat.</li>
                <li>Požadujte <strong>nejnovější technologie.</strong></li>
                <li>Pohlídejte si <strong>jednotné řízení.</strong> Všechny systémy by se měly ovládat z jediného místa.</li>
              </ul>
              <div class="terc-flex">
                <img src="../assets/ikona-terc-white.svg" alt="ikona terč" class="terc-white">
              </div>
            </div>
          </div>
          <p class="blueh3 blue-text">Jen takto se dá docílit maximálně efektivního systému, který povede k úsporám.</p>
          <p><strong>Jakékoli napravování škod znamená neúměrně vysoké náklady.</strong></p>          
        </div>
      </div>

      <div class="page15 blue-bcg padding-blue-page" id="smysl">
        <img src="../assets/kresba-strana-16.svg" alt="four dots" class="display-center">
        <div class="margin-top-h2">
          <h2 class="blue-h2 margin-bottom-h2">Proč dává smysl</h2>
          <h2 class="blue-h2">spolupracovat s námi?</h2>
        </div>
      </div>

      <div class="page16 blue-bcg padding-blue-page">
        <img src="../assets/kresba-strana-15.svg" alt="four dots" class="display-center">
        <div>
          <h2 class="blue-h2 margin-bottom-h2">Protože od této chvíle</h2>
          <h2 class="blue-h2 margin-bottom-h2">budete chtít spolupracovat</h2>
          <h2 class="blue-h2 margin-bottom-h2">už jedině s profesionály.</h2>
          <h2 class="blue-h2 yellow-text">A to my jsme.</h2>
        </div>
      </div>

      <div class="page17 padding20 content">
        <ul class="list-checked">
          <li class="flex-list">
            <img src="../assets/ikona-hlava-zarovka.svg" alt="ikona-hlava-zarovka">
            <div>
              <p class="blue-text bold">Máme cenné know-how</p>
              <p>Automatizaci budov se věnujeme už od roku 2006 (viz obchodní rejstřík).</p>
            </div> 
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-tri figury.svg" alt="ikona-tri figury">
            <div>
              <p class="blue-text bold">Ručíme za kvalitu</p>
              <p>Máme početný tým vlastních projektantů, servisních techniků, SW vývojářů i dalších odborníků (viz naše kanceláře v Brně).</p>
            </div>
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-telefon.svg" alt="ikona-telefon">
            <div>
              <p class="blue-text bold">Kdykoli vám pomůžeme</p>
              <p>Servisní oddělení funguje 24 hodin denně (viz naše servisní zákaznická linka: +420 724 720 100).</p>
            </div>        
          </li>
          <li class="flex-list">
            <img src="../assets/ikona-ruce-spoluprace.svg" alt="ikona-ruce-spoluprace">
            <div>
              <p class="blue-text bold">Jsou za námi vidět výsledky</p>
              <p>Můžeme se pochlubit množstvím realizací po celé Evropě. Úspěšně spolupracujeme například s firmami Jaguar-Land Rover, Foxconn, Mondelez International, Skanska, Hyundai, Metrostav, Kaufland a další (viz naše referenční stavby).</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="page18 content">
        <h3 class="blue-text h3 b40">Co od nás můžete čekat?</h3>
        <div class="list">
          <p>Ve vašem zájmu vám <strong>povíme i nepříjemné věci.</strong> Třeba že potřebujete přepracovat původní nekvalitní projekt od jiného dodavatele.</p>
          <p>Vypracujeme projekt, který obstojí při nejpřísnějších certifikacích <strong>BREEAM, LEED, případně LEED Platinum.</strong></p>
          <p>Projektovou dokumentaci <strong>přizpůsobíme charakteru plánované budovy</strong>, zohledníme její specifika.</p>
          <p><strong>Může jít o:</strong></p>
          <ul class="list-checked">
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Administrativní budovy</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Budovy úřadů</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Obchodní řetězce</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Hotely</p>
            </li>
            <li class="flex-list-p6">
              <img src="../assets/checked.svg" alt="ikona větrák">
              <p>Výrobní podniky</p>
            </li>
          </ul>          
        </div>
      </div>
      <img src="../assets/Vector-2.svg" alt="obrysy budov" class="buildings-11">

      <div class="page20 full-bcg-home full-height blue-bcg" id="kontakt">
        <div class="end text-center">
          <h2 class="blue-h2">Zaujala vás naše řešení?</h2>
          <p class="uppercase bold">Rádi připravíme nabídku na míru i vám.</p>
          <div class="address">
            <p>BUILDSYS a.s.</p>
            <p>Cihlářská 19</p>
            <p>602 00 Brno</p>
            <p><a href="tel:+420515919888" class="href">Tel.: +420 515 919 888</a></p>
            <p><a href="mailto:info@buildsys.cz" class="href">info@buildsys.cz</a></p>
          </div>
          <div class="address">
            <a href="http://www.buildsys.cz" target="_blank" class="href">www.buildsys.cz</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>
